.App {
  width:100%;
  height:auto;
  max-width: none;
  /* display: grid; */
  padding: 0px;
  margin: 0px;
  border: 0px;
  /* grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 50px calc(100vh - 100px) 50px; */
}

.App-logo {
  height: 40px;
  pointer-events: none;
  display: flex;
  float: left;
  margin-left: 20px;
  /* margin-top: 10px;
  margin-bottom: 10px; */
}

.App-name {
  display: flex;
  text-align: center;
  align-items: center;
  /* padding-top: 10px;
  padding-bottom: 10px; */
  font-size: 30px;
  font-family: Impact;
  margin-left: 65px;
  /* margin-top: 10px;
  margin-bottom: 10px; */
}

.App-header {
  /* background-color: #01050b; */
  height: 60px;
  color: white;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 60px;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.container-logo {
  position: relative;
  display: block;
  float: left;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 10px;
  height: 60px;
}

.container-title {
  position: relative;
  display: flex;
  float: center;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.container-app-default  {
  width:100%;
  height:100%;
  max-width: none;
  display: grid;
  padding: 0px;
  margin: 0px;
  border: 0px;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: calc(100vh - 60px);
}

.container-app-mobile  {
  width:100%;
  height:100%;
  max-width: none;
  display: grid;
  padding: 0px;
  margin: 0px;
  border: 0px;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: calc(100vw) auto;
}

.container-app-mobile-landscape  {
  width:100%;
  height:100%;
  max-width: none;
  display: grid;
  padding: 0px;
  margin: 0px;
  border: 0px;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: calc(90vh) auto;
}

.container-menu-default  {
  color: #FFFFFF;
  background-color: #01050b;
  border-right: groove;
  border-color: #000000;
  grid-column: span 2;
  grid-row: span 1;
}

.container-menu-mobile  {
  color: #FFFFFF;
  background-color: #01050b;
  border-right: groove;
  border-color: #000000;
  grid-column: span 12;
}

.container-menu-mobile-landscape  {
  color: #FFFFFF;
  background-color: #01050b;
  border-right: groove;
  border-color: #000000;
  grid-column: span 12;
}

.container-plot-default {
  width: 100%;
  color: #000000;
  background-color:  #010814;
  grid-column: span 10;
}

.container-plot-mobile {
  width: 100%;
  color: #000000;
  background-color:  #010814;
  grid-column: span 12;
  grid-row: span 1;
}

.container-plot-mobile-landscape {
  width: 100%;
  color: #000000;
  background-color:  #010814;
  grid-column: span 12;
  grid-row: span 1;
}

.container-disclaimer {
  display: flex;
  justify-content: right;
  align-items: right;
  justify-content: right;
  padding-right: 2px;
}

header {
  color: #FFFFFF;
  background-color: #696e75;
  grid-column: span 12;
  border-bottom: groove;
  border-color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: larger;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  text-align: center;
}

footer {
  color: #FFFFFF;
  background-color: #696e75;
  border-top: groove;
  border-color: #000000;
  grid-column: span 12;
  display: grid;
  justify-content: center;
  align-items: center;
  font-size: 0.75em;
  letter-spacing: 0.04em;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  text-align: left;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
}

footer p {
  margin: auto;
}