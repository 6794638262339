.axisX path {
    stroke: #ffffff;
}

.axisX line {
    stroke: #ffffff;
}

.axisX text {
    fill: #ffffff;
}

.axisY path {
    stroke: #ffffff;
}

.axisY line {
    stroke: #ffffff;
}

.axisY text {
    fill: #ffffff;
}

div.tooltip {
    position: absolute;
    text-align: center;
    padding: .2rem;
    background: #313639;
    color: #f9f9f9;
    border: 0px;
    border-radius: 8px;
    pointer-events: none;
    font-size: .7rem;
}

.line {
    fill: none;
    stroke: #33c23d;
    stroke-width:1.5px;
}

.plot-box {
    height: 100%;
    width: 100%;
    color: #000000;
    background-color:  #010814;
}
