.Menu {
  width:100%;
  height:100%;
  max-width: none;
  display: block;
  padding: 10px;
  margin: 0px;
  border: 0px;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(10, 1fr);
  row-gap: 2px;
}

h5 {
  /* grid-column: span 3; */
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: medium;
}

.Description {
  /* grid-column: span 3;
  grid-row: span 4; */
  position: relative;
  text-align: left;
  align-items: left;
  justify-content: bottom;
  font-size: smaller;
  padding-top: 20px;
  border-top: groove;
  border-top-width: 1px;
}

.mb-3 {
  width: 100%;
  height: auto;
  max-width: none;
  display: grid;
  padding: 2px;
  margin: 0px;
  border: 0px;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(1, 1fr);
  row-gap: 2px;
}

.email-text {
  grid-column: span 2;
  grid-row: span 1;
  display: flex;
  text-align: left;
  align-items: center;
  justify-content: left;
  font-size: small;
}

.button-submit {
  grid-column: span 1;
  grid-row: span 1;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: small;
}

.Description p {
  font-size: smaller;
  width: 100%;
}

.Dropdowns {
  grid-column: span 3;
  grid-row: span 2;
  position: relative;
  text-align: left;
  align-items: left;
  justify-content: bottom;
  font-size: small;
  padding-top: 20px;
  padding-bottom: 20px;

}

.Model-performance {
  grid-column: span 3;
  grid-row: span 1;
  justify-content: center;
  align-items: center;
  font-size: large;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  text-align: center;
  opacity: 1.0;
  padding-top: 10px;
}

.Model-performance p{
  font-size: large;
  color: mediumspringgreen;
}

.Model-selection {
  grid-column: span 3;
  grid-row: span 3;
  justify-content: center;
  align-items: center;
  font-size: small;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  text-align: center;
  opacity: 1.0;
}

.Market-select {
  grid-column: span 3;
  grid-row: span 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: small;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  text-align: center;
  background-color: #ffffff;
  border: groove;
  border-color: #e3e3e3;
  opacity: 1.0;
}

.Model-select {
  grid-column: span 3;
  grid-row: span 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: small;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  text-align: center;
  background-color: #ffffff;
  border: groove;
  border-color: #e3e3e3;
  opacity: 1.0;
}
