.Disclaimer {
  background-color: #696e75;
  border: #696e75;
  font-family: inherit;
  font-size: 1.0em;
  text-decoration-line: underline;
}

.Disclaimer.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #696e75;
  --bs-btn-border-color: #696e75;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #696e75;
  --bs-btn-hover-border-color: #696e75;
  --bs-btn-focus-shadow-rgb: 49,132,253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #696e75;
  --bs-btn-active-border-color: #696e75;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #696e75;
  --bs-btn-disabled-border-color: #696e75;
}